<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-22 14:15:37
 * @Description  : 
-->
<template>
    <div class="container" :style="{ minHeight: boxHeight }">
        <qrcode-stream class="camera" @decode="onDecode"></qrcode-stream>

        <img class="img-style" :src="require('@/assets/imgs/png/qr_bg.png')" />
        <div class="tip">將QR Code放入掃瞄框內即可租借</div>
        <div class="tip2">
            <div id="toggleFlashlight">
                <span> {{ this.flashOn ? "關閉閃光燈" : "打開閃光燈" }}</span>
            </div>
            <img
                @click="toggleFlashlight"
                width="50"
                :src="flashOn ? flashlightOpen : flashlightClose"
                alt=""
                srcset=""
            />
        </div>

        <div
            class="header container-pa16 flex-container space-between width-fill"
        >
            <img
                class="width-36"
                :src="require('@/assets/imgs/svg/cancel.svg')"
                @click="$_back()"
            />
        </div>
    </div>
</template>

<script>
// import { Toast } from "vant";
export default {
    data: () => ({
        show: true,
        flashlightOpen: require("@/assets/imgs/png/ic_open.png"),
        flashlightClose: require("@/assets/imgs/png/ic_close.png"),
        flashOn: false,
        stream: null, // 用于存储媒体流
    }),
    components: {},
    methods: {
        $_back() {
            history.go(-1);
        },
        onDecode(decodedString) {
            console.log("decodedString: ", decodedString);
            if (decodedString) {
                // this.$emit("decode", decodedString);
                this.$router.push({
                    name: "map",
                    params: {
                        id: decodedString,
                    },
                });
            }
        },
        toggleFlashlight() {
            if (this.flashOn) {
                // 关闭闪光灯
                this.flashOn = false;
                this.stopMediaStream();
            } else {
                // 打开闪光灯
                if (!this.stream) {
                    // 如果还没有获取媒体流，则先获取
                    this.getMediaStreamWithFlash();
                } else {
                    this.startMediaStream();
                }
                this.flashOn = true;
            }
        },
        startMediaStream() {
            if (this.stream) {
                const tracks = this.stream.getVideoTracks();
                if (tracks.length > 0) {
                    const videoTrack = tracks[0];
                    const currentSettings = videoTrack.getSettings();
                    if ("torch" in currentSettings) {
                        videoTrack
                            .applyConstraints({
                                advanced: [{ torch: true }],
                            })
                            .catch((error) => {
                                console.error(
                                    "Failed to turn on flashlight:",
                                    error
                                );
                            });
                        // alert("閃光燈已開啟");
                    } else {
                        alert("此裝置不支援閃光燈");
                    }
                }
            }
        },
        getMediaStreamWithFlash() {
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: "environment", // 后置摄像头
                        advanced: [{ torch: true }],
                    },
                })
                .then((stream) => {
                    this.stream = stream;
                    const videoTrack = stream.getVideoTracks()[0];
                    if (videoTrack.getSettings().torch) {
                        // alert("閃光燈已開啟");
                        this.flashOn = true;
                    }
                })
                .catch((error) => {
                    console.error("Error accessing camera:", error);
                });
        },
        stopMediaStream() {
            if (this.stream) {
                const tracks = this.stream.getVideoTracks();
                if (tracks.length > 0) {
                    const videoTrack = tracks[0];
                    const currentSettings = videoTrack.getSettings();
                    if ("torch" in currentSettings) {
                        videoTrack.applyConstraints({
                            advanced: [{ torch: false }],
                        }).catch((error) => {
                            console.error("Failed to turn off flashlight:", error);
                        });
                        // alert("閃光燈已關閉");
                    }
                }

                // 停止所有轨道
                this.stream.getTracks().forEach((track) => track.stop());
                this.stream = null;
                this.flashOn = false;
            }
        },
    },
    beforeDestroy() {
        // 对于 Vue 2
        // unmounted() { // 对于 Vue 3
        this.stopMediaStream(); // 在组件销毁时关闭媒体流和闪光灯
    },
    computed: {
        boxHeight() {
            return `${window.innerHeight}px`;
        },
    },
};
</script>

<style scoped lang="less">
.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.block {
    width: 120px;
    height: 120px;
    background-color: #fff;
}
.camera {
    border: 2px solid lightblue;
    position: absolute;
}

.img-style {
    position: absolute;
    // top: 50px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //  width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.tip {
    color: white;
    position: absolute;
    bottom: 0;
    margin-bottom: 210px;
}
.tip2 {
    position: fixed;
    color: white;
    // position: absolute;

    bottom: 0;
    margin-bottom: 80px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
